<template>
	<a-popover
		title="Descrição dos valores - R$"
		trigger="hover"
		placement="left"
	>
		<a-row slot="content" class="content" style="width: 260px;">
			<a-row type="flex" justify="space-between">
				<a-col>Forma de pagamento</a-col>
				<a-col class="info">{{ group_name }}</a-col>
			</a-row>
			<a-row
				v-if="match('Cartão de Crédito')"
				type="flex"
				justify="space-between"
			>
				<a-col>Bandeira</a-col>
				<a-col class="info">
					<img :src="paymentMethod.image" width="26" />
					<span style="margin-left: 4px;">{{
						paymentMethod.name
					}}</span>
				</a-col>
			</a-row>
			<a-row
				v-if="match('Cartão de Débito')"
				type="flex"
				justify="space-between"
			>
				<a-col>Bandeira</a-col>
				<a-col class="info">
					<img :src="paymentMethod.image" width="22" height="14" />
					<span style="margin-left: 4px;">{{
						paymentMethod.name
					}}</span>
				</a-col>
			</a-row>
			<a-row v-if="match('Dinheiro')" type="flex" justify="space-between">
				<a-col>Troco para</a-col>
				<a-col class="info">{{ toPrice(changeFor) }}</a-col>
			</a-row>
			<a-row type="flex" justify="space-between">
				<a-col>Subtotal</a-col>
				<a-col class="info">{{ toPrice(price) }}</a-col>
			</a-row>
			<a-row type="flex" justify="space-between">
				<a-col>Gorjeta</a-col>
				<a-col class="info">+ {{ toPrice(tip) }}</a-col>
			</a-row>
			<a-row type="flex" justify="space-between">
				<a-col>Cashback usado</a-col>
				<a-col class="info">- {{ toPrice(cashback) }}</a-col>
			</a-row>
			<a-divider style="margin: 5px 0;" />
			<a-row type="flex" justify="space-between" class="total">
				<a-col>Valor total</a-col>
				<a-col class="info">{{ TotalValue }}</a-col>
			</a-row>
		</a-row>
		<slot></slot>
	</a-popover>
</template>

<script>
export default {
	name: 'TotalValuePopover',
	props: {
		price: {
			type: [String, Number],
			default: '0.00',
		},
		tip: {
			type: [String, Number],
			default: '0.00',
		},
		cashback: {
			type: [String, Number],
			default: '0.00',
		},
		paymentMethod: {
			type: Object,
			default: function () {
				return null
			},
		},
		changeFor: {
			type: [String, Number],
			default: '0.00',
		},
	},
	computed: {
		TotalValue: function () {
			return this.toPrice(
				parseFloat(this.price) +
					parseFloat(this.tip) -
					parseFloat(this.cashback)
			)
		},
		group_name: function () {
			return this.paymentMethod
				? this.paymentMethod.group_name
				: 'Não informado'
		},
	},
	methods: {
		isValid(value) {
			return value !== null && value > 0.0
		},
		toPrice(value) {
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(value)
				.replace(/R\$/g, '')
		},
		match(method) {
			return (
				this.paymentMethod && this.paymentMethod.group_name === method
			)
		},
	},
}
</script>

<style lang="less" scoped>
.content {
	flex-direction: column;
	text-align: end;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);
	width: 200px;

	.info {
		font-weight: 501;
		color: rgba(0, 0, 0, 0.85);
	}

	.total {
		padding-top: 7px;
		font-size: 14px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.65);
	}
}
</style>
