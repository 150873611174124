/* eslint-disable vue/attribute-hyphenation */
<template>
	<a-drawer
		title="Cancelamento"
		:visible="showDrawer"
		width="550px"
		:closable="false"
		@close="onClose"
	>
		<div class="drawer-header">
			<span>Comanda {{ order.number }}</span>
		</div>
		<div class="drawer-body">
			<a-row
				type="flex"
				justify="center"
				style="width: 100%;"
				align="middle"
			>
				<a-col :xl="3">
					<span class="label">Opção:</span>
				</a-col>
				<a-col :xl="18">
					<a-select
						v-model="flux_option"
						class="select"
						@change="() => (message = '')"
					>
						<a-select-option
							v-for="option in select_options"
							:key="option.value"
							:value="option.value"
						>
							{{ option.text }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row
				v-if="flux_option === 0"
				type="flex"
				class="centered"
				align="middle"
			>
				<a-col :xl="3">
					<span class="label">Motivo: </span>
				</a-col>
				<a-col :xl="18">
					<a-textarea
						v-model="message"
						placeholder="Informe o motivo do cancelamento"
						auto-size
						style="width: 80%;"
					/>
				</a-col>
			</a-row>
			<a-row v-if="showFlux" class="flux-content">
				<a-row v-if="flux_option === 1">
					<div class="missingHeader">
						<div style="font-weight: 525; padding-bottom: 8px;">
							Selecione os elementos que serão cancelados:
						</div>
					</div>
					<div
						v-for="element in report"
						:key="element.id"
						class="element"
					>
						<a-row type="flex" justify="start" :gutter="16">
							<a-col>
								<a-checkbox v-model="element.checked" />
							</a-col>
							<a-col >
								<span 
									style="cursor: pointer;"
									@click="() => element.checked = !element.checked"
								>
									{{ element.item_name }}
								</span>
							</a-col>
						</a-row>
						<a-row class="reject-message">
							<a-input
								v-if="element.checked"
								v-model="element.message"
								size="small"
								placeholder="Informe o motivo do cancelamento do item"
								style="width: 100%;"
							/>
						</a-row>
					</div>
				</a-row>
			</a-row>
		</div>
		<div class="drawer-footer">
			<a-button style="margin-right: 8px;" @click="onClose">
				Cancelar
			</a-button>
			<a-button type="primary" :disabled="!validated" @click="toCancel">
				Enviar
			</a-button>
		</div>
	</a-drawer>
</template>

<script>
import { orderByCreatedAt, capitalizeFirstLetter } from '@/utils/utils'

const select_options = [
	{ value: 0, text: 'Cancelar a comanda.' },
	{ value: 1, text: 'Cancelar itens da comanda' },
]

export default {
	name: 'CancelOrderDrawer',
	data() {
		return {
			showDrawer: false,
			flux_option: 0,
			report: [],
			order: {},
			select_options,
			message: '',
			capitalizeFirstLetter,
		}
	},
	computed: {
		validated: function () {
			return this.flux_option === 0 ? true : 
				this.report.length > 0 && this.report.filter(el => el.checked ).length > 0
		},
	},
	methods: {
		setShowDrawer(order) {
			this.showDrawer = true
			this.order = order
			this.mount()
		},
		onClose() {
			this.showDrawer = false
			this.clearDrawer()
		},
		onChange(checkedValues) {
			this.checkeds = checkedValues
		},
		toCancel() {
			if(this.flux_option === 0){
				this.$emit('order', {
					order_id: this.order.id,
					reason: this.capitalizeFirstLetter(this.message),
				})
			} else {
				this.$emit('elements', {
					order_id: this.order.id,
					data: this.report
						.filter(el => el.checked )
						.map(el => {
							return {
								id: el.id,
								rejection_reason: this.capitalizeFirstLetter(el.message),
							}
						}),
				})
			}

			this.showDrawer = false
			this.clearDrawer()
		},
		clearDrawer() {
			this.report = []
			this.order = {}
			this.message = ''
		},
		showFlux() {
			return this.flux_option !== null && this.flux_option !== 0
		},
		mount() {
			const block = ['REJECTED', 'CANCELED']

			this.report = this.order.elements
				.filter((element) => !block.includes(element.status))
				.sort(orderByCreatedAt)
				.map((element) => {
					return {
						id: element.id,
						item_name: `${element.amount}x ${element.item_name}`,
						checked: false,
						message: '',
					}
				})
		},
	},
}
</script>

<style lang="less" scoped>
.drawer-header {
	font-weight: bold;
	margin: 0 0 20px 10px;
	color: orange;
}
.drawer-body {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 28px;

	.select {
		width: 80%;
	}

	.label {
		float: right;
		padding-right: 10px;
	}

	.centered {
		display: flex;
		justify-content: center;
		margin: 20px 0;
	}

	.flux-content {
		width: 100%;

		.missingHeader {
			padding: 10px 10px 5px 10px;
			margin-top: 20px;
			border-top: 1px solid hsv(0, 0, 85%);
			border-bottom: 1px solid hsv(0, 0, 85%);
		}

		.blockquote {
			font-size: 90%;
			color: #697b8c;
			border-left: 4px solid #ebedf0;
			padding-left: 0.8em;
			margin: 1em 0;
		}

		.element {
			padding: 8px 0 0px 30px;
			background: #fafafa;
			// border-bottom: 1px solid hsv(0, 0, 85%);
			color: #000;

			.element-subitem-header {
				color: rgba(0, 0, 0, 0.3);
				font-size: 12px;
				display: flex;
				width: 100%;
			}

			.reject-message {
				padding: 15px 20px 5px 0;
			}
		}

		.checkbox {
			padding: 12px 0px 0px 50px;
			display: flex;
			flex-direction: column;
		}
	}
}

.drawer-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid hsv(0, 0, 85%);
	padding: 10px 16px;
	text-align: right;
	left: 0;
	background: #fff;
	border-radius: 0 0 4px 4px;
	margin-top: 25px;
}
</style>
