<template>
	<a-popover
		title="Histórico do pedido"
		trigger="click"
		placement="rightBottom"
	>
		<div slot="content" style="margin: 16px 0px 0px 8px;">
			<a-timeline>
				<a-timeline-item
					v-for="(event, idx) in history"
					:key="event.date"
					:color="statusColor(event.status)"
				>
					<a-icon
						v-if="event.status === 'RESTRICTION'"
						slot="dot"
						type="exclamation-circle"
						style="font-size: 14px;"
					/>
					<a-icon
						v-else-if="idx + 1 === history.length"
						slot="dot"
						type="clock-circle"
						style="font-size: 14px;"
					/>
					<a-row type="flex" justify="space-between" :gutter="24">
						<a-col class="event-description">
							{{ eventDescription(event, idx) }}
						</a-col>
						<a-col class="event-hour">
							{{ moment(event.date).format('HH:mm') }}
						</a-col>
					</a-row>
				</a-timeline-item>
			</a-timeline>
		</div>
		<a-button type="link" class="see-history">
			VER HISTÓRICO
		</a-button>
	</a-popover>
</template>

<script>
const COMAND_STATUS_COLOR = {
	WAITING: 'gray',
	OPEN: '#f57d31',
	PAYING: 'gray',
	RECEIVING: '#f57d31',
	PAID: '#f57d31',
	FINISHED: '#f57d31',
	CANCELED: 'red',
	RESTRICTION: 'red',
}

export default {
	name: 'OrderHistoryPopover',
	props: {
		history: {
			type: Array,
			required: true,
		},
	},
	computed: {
		isAvalued: function () {
			return (
				this.history.filter((e) => e.status === 'FINISHED').length > 1
			)
		},
	},
	methods: {
		statusColor(status) {
			return COMAND_STATUS_COLOR[status]
		},
		eventDescription(event, idx) {
			switch (event.status) {
				case 'WAITING':
					return idx + 1 === this.history.length
						? 'Pedido recebido'
						: 'Novos elementos adicionados pelo cliente'
				case 'OPEN':
					return 'Elementos do pedido aceitos'
				case 'RESTRICTION':
					return 'Elementos do pedido com restrições'
				case 'PAYING':
					return 'Cliente solicitou efetuar o pagamento'
				case 'PAID':
					return 'Pagamento efetuado'
				case 'RECEIVING':
					return 'Pagamento solicitado ao cliente'
				case 'FINISHED':
					return idx === 0 && this.isAvalued
						? 'Pedido avaliado pelo cliente'
						: 'Pedido finalizado'
				case 'CANCELED':
					return 'Pedido cancelado'
				default:
					return event.status
			}
		},
	},
}
</script>

<style lang="less" scoped>
.event-description {
	margin-bottom: 0;
}

.event-hour {
	font-size: 13px;
	color: rgba(0, 0, 0, 0.5);
	font-weight: 401;
}
</style>
