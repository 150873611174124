<template>
	<div class="container">
		<a-divider
			orientation="left"
			class="divider"
			dashed
			:style="dividerColor"
		>
			{{ title }}
		</a-divider>
		<a-collapse
			:bordered="false"
			:active-key="activedKeys"
			style="margin-top: -10px; background: #fff;"
		>
			<template v-slot:expandIcon="props">
				<a-tooltip placement="top" title="Mais informações">
					<a-icon
						type="caret-right"
						:rotate="props.isActive ? 90 : 0"
					/>
				</a-tooltip>
			</template>
			<a-collapse-panel
				v-for="element in elements"
				:key="element.id"
				:show-arrow="showArrow(element)"
				class="panel"
				:style="slide(element)"
			>
				<template v-slot:header>
					<a-row
						type="flex"
						justify="space-between"
						class="element-header"
					>
						<a-col :lg="10">
							<span style="margin-right: 10px;">
								{{ element.amount }}
								<span
									style="font-size: 12px; margin-left: -4px;"
								>
									x
								</span>
							</span>
							<span v-if="element.product_external_code">
								{{ `${element.product_external_code} - ` }}
							</span>
							<a-tooltip
								placement="top"
								title="Item com observações"
							>
								<a-badge
									v-if="element.observations !== ''"
									status="error"
									style="margin: 0 -5px;"
								/>
							</a-tooltip>
							<span>{{ element.item_name }}</span>
							<a-popover
								v-if="element.rejection_reason"
								:title="getPopoverTitle(element.status)"
							>
								<template slot="content">
									<p>{{ element.rejection_reason }}</p>
								</template>
								<a-icon
									type="info-circle"
									style="
										margin-left: 8px;
										font-size: 12px;
										color: #108ee9;
									"
								/>
							</a-popover>
						</a-col>
						<a-col :lg="3" :offset="1">
							<span class="cifrao">R$</span>
							{{ showPrice(element) }}
						</a-col>
						<a-col :lg="2">
							<span class="cifrao">R$</span>
							{{ showTotal(element) }}
						</a-col>
						<a-col :lg="2">
							<a-tag :color="statusColor(element)">
								{{ statusName(element) }}
							</a-tag>
						</a-col>
						<a-col>
							<a-icon
								v-if="showRegister(element)"
								type="check-circle"
								theme="filled"
								:style="registerIconColor(element)"
								@click="handleClickRegister(element.id)"
							/>
							<a-icon v-else type="minus" style="color: white;" />
						</a-col>
					</a-row>
				</template>
				<div style="margin-left: 47px;">
					<div
						v-if="element.subsections.length > 0"
						class="item-price"
					>
						<span>Preço do item - </span>
						<span class="cifrao">R$</span>
						<span>{{ showItemPrice(element.item_price) }}</span>
					</div>
					<ElementSubitemsList
						v-for="subsection in element.subsections"
						:key="subsection.subsection_id"
						:subsection="subsection"
					/>
					<div v-if="element.observations !== ''" class="observation">
						<div>Observações:</div>
						<div>{{ element.observations }}</div>
					</div>
				</div>
			</a-collapse-panel>
		</a-collapse>
	</div>
</template>

<script>
import ElementSubitemsList from './elementSubitemsList.vue'
import { mapActions, mapGetters } from 'vuex'

const getPriceValue = (subsection) => {
	let { subelements, billing_method } = subsection
	const values = subelements
		.filter((subelement) => subelement.subitem_available)
		.map((subelement) => {
			return {
				price: parseFloat(subelement.subitem_price),
				amount: subelement.amount,
			}
		})

	if (billing_method === 'BGT') {
		return Math.max.apply(
			Math,
			values.map((el) => el.price * el.amount)
		)
	} else {
		const sum = values.reduce(
			(sum, curr) => sum + curr.price * curr.amount,
			0
		)
		const div = values.reduce((sum, curr) => sum + curr.amount, 0)

		if (div > 0) {
			return billing_method === 'AVG' ? sum / div : sum
		} else return 0
	}
}

export default {
	name: 'ElementListItem',
	components: {
		ElementSubitemsList,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		elements: {
			type: Array,
			required: true,
		},
		orderId: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: '#3574b6',
		},
	},
	data() {
		return {
			getPriceValue,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		dividerColor: function () {
			return { color: this.color }
		},
		activedKeys: function () {
			const keys = this.elements
				.filter(
					(element) =>
						element.subsections.length > 0 ||
						element.observations !== ''
				)
				.map((element) => element.id)

			return keys
		},
	},
	methods: {
		...mapActions('orders', ['registerElement']),
		showArrow(element) {
			return element.subsections.length > 0 || element.observations !== ''
		},
		slide(element) {
			return this.showArrow(element) ? '' : 'padding-left: 29px'
		},
		showTotal(element) {
			let price =
				element.amount *
				element.subsections.reduce(
					(sum, curr) => sum + getPriceValue(curr),
					parseFloat(element.item_price)
				)

			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(price)
				.replace(/R\$/g, '')
		},
		statusName(element) {
			var COMAND_STATUS_NAMES = {
				Aguardando: 'WAITING',
				Aceito: 'ACCEPTED',
				Recusado: 'REJECTED',
				Entregue: 'DELIVERED',
				Cancelado: 'CANCELED',
			}
			for (var key in COMAND_STATUS_NAMES) {
				if (COMAND_STATUS_NAMES[key] === element.status) return key
			}

			return ''
		},
		statusColor(element) {
			var COMAND_STATUS_COLOR = {
				WAITING: 'orange',
				ACCEPTED: 'green',
				REJECTED: 'volcano',
				DELIVERED: 'cyan',
				CANCELED: 'red',
			}

			return COMAND_STATUS_COLOR[element.status]
		},
		showPrice(element) {
			let price = element.subsections.reduce(
				(sum, curr) => sum + getPriceValue(curr),
				parseFloat(element.item_price)
			)

			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(price)
				.replace(/R\$/g, '')
		},
		showItemPrice(price) {
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(price)
				.replace(/R\$/g, '')
		},
		showRegister(element) {
			const allowedStatus = ['ACCEPTED', 'DELIVERED']
			return allowedStatus.includes(element.status)
		},
		registerIconColor(element) {
			return element.external_register
				? { color: '#87d068' }
				: { color: '#e8e8e8' }
		},
		handleClickRegister(id) {
			const element = this.elements.find((el) => el.id === id)

			if (!element.external_register) {
				const payload = {
					merchantId: this.selectedMerchantId,
					orderId: this.orderId,
					elements_id: [id],
				}

				element.external_register = true
				this.registerElement(payload)
			}
		},
		getPopoverTitle(status) {
			return `Motivo ${
				status === 'CANCELED' ? 'do cancelamento' : 'da rejeição'
			}`
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	padding: 5px 0 10px 0;

	.divider {
		font-size: 12px;
		margin: -12px auto;
		padding-bottom: 5px;
	}

	.panel {
		border: none;
	}

	.element-header {
		width: 100%;
		font-size: 14px;

		.centered-col {
			display: flex;
			justify-content: center;
		}

		.cifrao {
			font-size: 9px;
			margin-right: -2px;
			color: rgba(0, 0, 0, 0.6);
		}
	}

	.observation {
		width: 80%;
		margin-top: -8px;
		font-size: 14px;
		> div:first-child {
			color: red;
		}
		> div:last-child {
			padding-left: 10px;
		}
	}

	.item-price {
		margin: -8px 0px 12px 0;
		font-size: 12px;
		font-weight: lighter;
		color: #888888;

		.cifrao {
			font-size: 9px;
			margin-right: 2px;
		}
	}
}
</style>
