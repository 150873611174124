<template>
	<a-list item-layout="horizontal" :data-source="list">
		<a-list-item slot="renderItem" slot-scope="item">
			<a-radio slot="actions" :checked="item.checked" @click="onClick(item.id)"/>
			<a-list-item-meta @click="onClick(item.id)" >
				<img  slot="avatar" :src="item.image" width="32" style="padding-top: 3px;">
				<span slot="title">{{ item.name }}</span>
			</a-list-item-meta>
		</a-list-item>
	</a-list>
</template>

<script>
export default {
	name: 'PaymentMethodsList',
	props: {
		data: {
			type: Array,
			required: true,
		},
		selected: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			list: [],
		}
	},
	watch: {
		selected: function(newId){
			this.list.forEach(item => {
				item.checked = item.id === newId
			})
		},
		data: function(values) {
			this.list = values.map(e => {
				return {
					id: e.id, 
					image: e.image,
					name: e.name,
					checked: e.id === this.selected,
				}
			}) 
		},
	},
	created(){
		this.list = this.data.map(e => {
				return {
					id: e.id, 
					image: e.image,
					name: e.name,
					checked: e.id === this.selected,
				}
			}) 
	},
	methods: {
		onClick: function(id) {
			this.$emit('change', id)
		}
	}
}
</script>