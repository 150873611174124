<template>
	<div>
		<a-row type="flex" justify="space-between" class="element-subitem">
			<a-col :lg="10">
				<span style="margin: 0 10px;">
					{{ subitem.amount }}<span style="font-size: 12px;">x</span>
				</span>
				<span v-if="subitem.product_external_code">
					{{ `${subitem.product_external_code} - ` }}
				</span>
				<span>{{ subitem.subitem_name }}</span>
				<span
					v-if="!subitem.subitem_available"
					style="font-weight: bold;"
				>
					<a-icon
						type="exclamation-circle"
						style="font-size: 12px; margin: 0 2px 0 16px;"
						theme="twoTone"
						two-tone-color="red"
					/>
					Em falta
				</span>
			</a-col>
			<a-col :lg="3" :offset="1" style="padding-left: 10px;">
				<span v-if="subitem.subitem_price !== 0">
					<span class="cifrao">R$</span>
					{{ showPrice() }}
				</span>
				<a-icon
					v-else
					type="minus"
					style="padding-left: 10px; font-size: 11px;"
				/>
			</a-col>
			<a-col :lg="2"> </a-col>
			<a-col :lg="2"> </a-col>
			<a-col> </a-col>
		</a-row>
	</div>
</template>
<script>
export default {
	name: 'ElementSubitem',
	props: {
		subitem: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isMissing: !this.subitem.subitem_available,
		}
	},
	methods: {
		showPrice() {
			return Number(this.subitem.subitem_price)
				.toFixed(2)
				.replace(/\./g, ',')
		},
	},
}
</script>

<style lang="less" scoped>
.element-subitem {
	padding-left: 0px;
	font-size: 12px;
	color: #000;

	.centered-col {
		display: flex;
		justify-content: center;
	}

	.cifrao {
		font-size: 9px;
		margin-right: -2px;
		color: rgba(0, 0, 0, 0.6);
	}
}
</style>
