<template>
	<div>
		<ul v-if="loading" class="order-container">
			<li v-for="order in [1, 2, 3, 4, 5]" :key="order">
				<OrdersListItem
					:loading="loading"
					:order="{ id: order, elements: [] }"
				/>
			</li>
		</ul>
		<ul v-else-if="orders.length > 0" class="order-container">
			<li v-for="order in data" :key="order.id">
				<OrdersListItem
					:order="order"
					:table="table"
					@accept="onAccept(order.id)"
					@reject="onReject(order)"
					@cancel="onCancel(order)"
					@deliver="onDeliver"
					@receivePayment="onReceivePayment"
					@requestPayment="onRequestPayment(order.id)"
					@finish="onFinalize(order.id)"
				/>
			</li>
		</ul>
		<a-row v-else>
			<p class="empty-list">
				Nenhum pedido a ser listado
			</p>
		</a-row>
		<RejectOrderDrawer
			ref="rejectOrderDrawer"
			@save="(payload) => onSendReject(payload)"
		/>
		<CancelOrderDrawer
			ref="cancelOrderDrawer"
			@order="onSendCancel"
			@elements="onSendCancelElements"
		/>
	</div>
</template>

<script>
import OrdersListItem from './ordersListItem'
import { RejectOrderDrawer, CancelOrderDrawer } from './drawer'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'OrdersList',
	components: {
		OrdersListItem,
		RejectOrderDrawer,
		CancelOrderDrawer,
	},
	props: {
		orders: {
			type: Array,
			required: true,
		},
		table: {
			type: Object,
			default: function () {
				return null
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		data: function () {
			return this.table === null ? this.orders : this.table.orders
		},
	},
	methods: {
		...mapActions('orders', [
			'acceptOrder',
			'rejectOrder',
			'cancelOrder',
			'cancelOrderElements',
			'finalizeOrder',
			'deliverOrder',
			'requestPayment',
			'receivePayment',
		]),
		async onAccept(order_id) {
			const payload = {
				orderId: order_id,
				merchantId: this.selectedMerchantId,
			}

			try {
				await this.acceptOrder(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
		async onSendReject(data) {
			const payload = {
				orderId: data.order_id,
				merchantId: this.selectedMerchantId,
				data: data.data,
			}

			try {
				await this.rejectOrder(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
		async onSendCancel(data) {
			const payload = {
				orderId: data.order_id,
				merchantId: this.selectedMerchantId,
				data: data,
			}

			try {
				await this.cancelOrder(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
		async onSendCancelElements(data) {
			const payload = {
				orderId: data.order_id,
				merchantId: this.selectedMerchantId,
				data: data.data,
			}

			try {
				await this.cancelOrderElements(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
		async onFinalize(order_id) {
			const payload = {
				orderId: order_id,
				merchantId: this.selectedMerchantId,
			}

			try {
				await this.finalizeOrder(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
		async onCancel(order) {
			this.$refs.cancelOrderDrawer.setShowDrawer(order)
		},
		async onDeliver(data) {
			const payload = {
				orderId: data.order_id,
				merchantId: this.selectedMerchantId,
				elements_id: data.elements_id,
			}

			try {
				await this.deliverOrder(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
		onReject(order) {
			this.$refs.rejectOrderDrawer.setShowDrawer(order)
		},
		async onReceivePayment(data) {
			const payload = {
				orderId: data.order_id,
				merchantId: this.selectedMerchantId,
				paymentMethodId: data.paymentMethodId,
			}

			try {
				await this.receivePayment(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
		async onRequestPayment(order_id) {
			const payload = {
				orderId: order_id,
				merchantId: this.selectedMerchantId,
			}

			try {
				await this.requestPayment(payload)
				this.$emit('reload')
			} catch (error) {
				//
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles');

.order-container {
	padding: 0;
	margin: 0;
	list-style-type: none;
}
</style>
