<template>
	<div>
		<a-popconfirm
			:visible="visible"
			ok-text="Confirmar"
			cancel-text="Fechar"
			:ok-button-props="{ props: { disabled: selected === '' } }"
			@confirm="handleOk"
			@cancel="cancel"
		>
			<a-icon slot="icon" type="credit-card" />
			<template v-slot:title>
				<p style="font-weight: 501;">Forma de pagamento:</p>
				<a-row>
					<a-radio-group
						v-model="groupName"
						button-style="solid"
						@change="onChangeGroup"
					>
						<a-radio-button
							value="Dinheiro"
							:desabled="!hasDebitOptions"
						>
							Dinheiro
						</a-radio-button>
						<a-radio-button
							value="Cartão de Crédito"
							:desabled="!hasCreditOptions"
						>
							Crédito
						</a-radio-button>
						<a-radio-button
							value="Cartão de Débito"
							:desabled="!hasDebitOptions"
						>
							Débito
						</a-radio-button>
					</a-radio-group>
				</a-row>
				<a-row v-if="groupName !== 'Dinheiro'">
					<div class="question">Qual a bandeira do cartão?</div>
					<PaymentMethodsList
						:data="list"
						:selected="selected"
						@change="onChangeSelected"
					/>
				</a-row>
				<a-divider style="margin: 16px 0 -4px 0;" />
			</template>
			<slot></slot>
		</a-popconfirm>
	</div>
</template>

<script>
import PaymentMethodsList from './paymentMethodsList'
import { mapGetters } from 'vuex'

export default {
	name: 'ConfirmPaymentModal',
	components: {
		PaymentMethodsList,
	},
	props: {
		paymentMethodsList: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			visible: false,
			groupName: '',
			selected: '',
			list: [],
		}
	},
	computed: {
		...mapGetters({
			allPaymentMethods: 'merchants/allPaymentMethods',
		}),
		hasMoneyOptions: function () {
			return this.groupOptions('MONEY').length > 0
		},
		hasCreditOptions: function () {
			return this.groupOptions('CREDIT_CARD').length > 0
		},
		hasDebitOptions: function () {
			return this.groupOptions('DEBIT_CARD').length > 0
		},
	},
	watch: {
		groupName: function (name) {
			this.list =
				name === 'Cartão de Crédito'
					? this.groupOptions('CREDIT_CARD')
					: this.groupOptions('DEBIT_CARD')
		},
	},
	methods: {
		setShowModal(method) {
			if (method === null) {
				if (this.hasMoneyOptions) {
					this.groupName = 'Dinheiro'
					this.selected = this.groupOptions('MONEY')[0].id
				} else if (this.hasCreditOptions) {
					this.groupName = 'Cartão de Crédito'
				} else if (this.hasDebitOptions) {
					this.groupName = 'Cartão de Débito'
				}
			} else {
				this.groupName = method.group_name
				this.selected = method.id
			}

			this.visible = true
		},
		handleOk() {
			this.visible = false
			this.$emit('save', this.selected)
			this.clearModal()
		},
		cancel() {
			this.visible = false
			this.clearModal()
		},
		clearModal() {},
		groupOptions: function (code) {
			const index = this.allPaymentMethods.findIndex((e) => e.id === code)

			return this.allPaymentMethods[index].payment_methods
		},
		onChangeGroup(e) {
			this.groupName = e.target.value
			if (e.target.value === 'Dinheiro') {
				this.selected = this.groupOptions('MONEY')[0].id
			} else {
				this.selected = ''
			}
		},
		onChangeSelected(id) {
			this.selected = id
		},
	},
}
</script>

<style lang="less" scoped>
.question {
	margin: 16px 0 8px 0;
	color: rgba(0, 0, 0, 0.45);
}
</style>
