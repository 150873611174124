<template>
	<div class="new-elements">
		<a-row type="flex" justify="start">
			<a-row
				type="flex"
				justify="space-between"
				class="new-elements-header"
			>
				<a-col :lg="10">
					<span style="margin-left: 100px;">Itens</span>
				</a-col>
				<a-col :lg="3" :offset="1">
					<span style="margin-left: 15px;">Preço unit.</span>
				</a-col>
				<a-col :lg="2">
					<span style="margin-left: 35px;">Total</span>
				</a-col>
				<a-col :lg="2">
					<span style="margin-left: 30px;">Status</span>
				</a-col>
				<a-col style="padding-left: 25px;">
					Lançar
				</a-col>
			</a-row>
		</a-row>
		<a-row type="flex" justify="space-between" class="element-container">
			<ElementListItem
				v-for="(partition, index) in partitions"
				:key="index"
				:title="partition.title"
				:elements="partition.elements"
				:order-id="orderId"
			/>
		</a-row>
	</div>
</template>

<script>
import ElementListItem from './elementListItem'
import { orderByCreatedAt } from '@/utils/utils'

const map = {
	NOW: 'Pronta entrega',
	BEFORE: 'Entrada',
	TOGETHER: 'Principal',
	AFTER: 'Sobremesa',
}
const types = ['NOW', 'BEFORE', 'TOGETHER', 'AFTER']
const openStatus = ['WAITING', 'ACCEPTED', 'REJECTED']

export default {
	components: {
		ElementListItem,
	},
	props: {
		title: { type: String, required: true },
		elements: { type: Array, required: true },
		orderId: { type: String, required: true },
	},
	data() {
		return {
			map,
			types,
			openStatus,
		}
	},
	computed: {
		openElements: function () {
			return this.elements
				.filter((e) => openStatus.includes(e.status))
				.sort(orderByCreatedAt)
		},
		finishedElements: function () {
			return this.elements
				.filter((e) => !openStatus.includes(e.status))
				.sort(orderByCreatedAt)
		},
		partitions: function () {
			let partitions = this.types
				.map((type) => {
					return {
						title: this.map[type],
						elements: this.openElements.filter(
							(e) => e.preferential_delivery_moment === type
						),
					}
				})
				.reduce((acc, obj) => {
					if (obj.elements.length > 0) acc.push(obj)
					return acc
				}, [])

			if (this.finishedElements.length > 0)
				partitions.push({
					title: 'Finalizados',
					elements: this.finishedElements,
				})

			return partitions
		},
		unRegistredElements: function () {
			const allowedStatus = ['ACCEPTED', 'DELIVERED']

			return this.elements.filter((element) =>
				allowedStatus.includes(element.status)
			)
		},
		hasUnregistred: function () {
			return this.unRegistredElements.length > 0
		},
	},
}
</script>

<style lang="less" scoped>
.new-elements {
	.new-elements-header {
		padding-top: 3px;
		color: rgba(0, 0, 0, 0.45);
		margin-bottom: 10px;
		width: 100%;

		.centered-col {
			display: flex;
			justify-content: center;
		}
	}
	.element-container {
		width: 100%;
	}
}
</style>
