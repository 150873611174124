<template>
	<div class="container">
		<div class="header">
			<span>{{ subsection.name }}</span>
			<span class="description">{{ billingDescription }}</span>
		</div>
		<div class="body">
			<ElementSubitem
				v-for="subelement in subelements"
				:key="subelement.id"
				:subitem="subelement"
			/>
		</div>
	</div>
</template>

<script>
import ElementSubitem from './elementSubitem'

const billingMethods = Object.freeze({
	SUM: '',
	BGT: ' - Maior valor',
	AVG: ' - Valor médio',
})

export default {
	name: 'ElementSubitemsList',
	components: {
		ElementSubitem,
	},
	props: {
		subsection: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			billingMethods,
		}
	},
	computed: {
		subelements: function () {
			return this.subsection.subelements
		},
		billingDescription: function () {
			return billingMethods[this.subsection.billing_method]
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	position: relative;
	margin-top: -13px;
	padding-left: 80px;

	.header {
		font-size: 12px;
		color: #888888;
		font-weight: 501;

		.description{
			color: #108ee9;
		}
	}
	.body {
		padding-left: 0px;
	}
}
</style>
