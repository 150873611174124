<template>
	<div>
		<a-popconfirm
			:visible="visible"
			ok-text="Confirmar"
			cancel-text="Cancelar"
			:ok-button-props="{ props: { disabled: !validated } }"
			@confirm="handleOk"
			@cancel="cancel"
		>
			<a-icon slot="icon" type="caret-right" style="color: #fff;" />
			<template v-slot:title>
				<a-row
					style="
						padding: 5px 0;
						border-bottom: 1px solid hsv(0, 0, 85%);
					"
				>
					<a-checkbox
						:indeterminate="indeterminate"
						:checked="allChecked"
						@change="onCheckAll"
					>
						{{ allChecked ? 'Desmarcar' : 'Marcar' }} todos
					</a-checkbox>
				</a-row>
				<a-row>
					<ul style="padding: 0;">
						<li
							v-for="element in report"
							:key="element.id"
							class="element-list"
						>
							<a-checkbox v-model="element.checked" />
							<span
								style="cursor: pointer; padding-left: 8px;"
								@click="() => element.checked = !element.checked"
							>
								{{ element.amount + 'x' }}
								{{ element.item_name }}
							</span>
						</li>
					</ul>
				</a-row>
			</template>
			<slot></slot>
		</a-popconfirm>
	</div>
</template>

<script>
export default {
	name: 'SetDeliveredModal',
	data() {
		return {
			visible: false,
			order: {},
			report: [],
		}
	},
	computed: {
		allChecked: function () {
			return this.count === this.report.length
		},
		indeterminate: function () {
			return this.count > 0 && this.count !== this.report.length
		},
		count: function () {
			let count = 0
			if (this.report.length > 0) {
				this.report.forEach((element) => {
					if (element.checked) count++
				})
			}

			return count
		},
		validated: function () {
			return this.allChecked || this.indeterminate
		},
	},
	methods: {
		setShowModal(order) {
			this.visible = true
			this.order = order
			this.mount()
		},
		handleOk() {
			this.visible = false
			this.$emit('save', {
				order_id: this.order.id,
				elements_id: this.allChecked
					? []
					: this.report.filter((e) => e.checked).map((e) => e.id),
			})
			this.clearModal()
		},
		cancel() {
			this.visible = false
			this.clearModal()
		},
		clearModal() {
			this.order = {}
		},
		mount() {
			this.report = this.order.elements
				.filter((element) => element.status === 'ACCEPTED')
				.map((element) => {
					return {
						id: element.id,
						item_name: element.item_name,
						amount: element.amount,
						checked: true,
					}
				})
		},
		onCheckAll(e) {
			if (this.report.length > 0)
				this.report.forEach(
					(element) => (element.checked = e.target.checked)
				)
		},
	},
}
</script>

<style lang="less" scoped>
.element-list {
	list-style: none;
	padding: 5px 0;
	margin: 0;
}
</style>
