<template>
	<a-popover title="Avaliação do pedido" trigger="hover" placement="right">
		<div slot="content">
			<p>
				<span style="font-weight: bold;">Nota: </span>
				<a-rate
					:default-value="stars"
					disabled
					style="font-size: 16px; margin-left: 5px;"
				/>
				<span style="margin-left: 5px;">{{ desc[stars - 1] }}</span>
			</p>
			<p class="container">
				<span style="font-weight: bold;">
					Comentário:
				</span>
				{{ comment }}
			</p>
		</div>
		<slot></slot>
	</a-popover>
</template>

<script>
export default {
	name: 'Ratepopover',
	props: {
		stars: {
			type: Number,
			required: true,
		},
		comment: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			desc: ['Destestei', 'Não gostei', 'normal', 'Gostei', 'Amei!'],
		}
	},
}
</script>

<style lang="less" scoped>
.container {
	width: 300px;
	max-width: 300px;
}
</style>
