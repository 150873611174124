<template>
	<div class="container">
		<a-collapse :accordion="true" :bordered="false">
			<template v-slot:expandIcon="props">
				<a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
			</template>
			<a-collapse-panel
				:key="order.id"
				class="collapse"
				style="border-bottom: 1px solid hsv(0, 0, 85%);"
			>
				<template v-slot:header>
					<a-skeleton
						:loading="loading"
						:title="false"
						:paragraph="{ rows: 2, width: ['100%', '65%'] }"
						active
					>
						<div class="header">
							<a-row
								type="flex"
								justify="space-between"
								:gutter="8"
							>
								<a-col
									:lg="6"
									class="order-client-name hide-overflow-text"
								>
									<span>
										<a-icon
											type="user"
											style="margin-right: 5px;"
										/>
										{{ order.requester_name }}
									</span>
								</a-col>
								<a-col :lg="4">
									<div v-if="order.type === 'LOCAL'">
										<span v-if="!table" class="place-name">
											<a-icon type="border" />
											Mesa {{ order.place_name }}
										</span>
									</div>
								</a-col>
								<a-col :lg="4">
									<span class="order-number">
										Pedido
										<span>
											{{ formatNumber(order.number) }}
										</span>
									</span>
								</a-col>
								<a-col :xl="5" :lg="5" :md="5">
									<a-tag :color="statusColor">
										{{ statusName }}
									</a-tag>
								</a-col>
								<a-col :lg="4" style="text-align: right;">
									<TotalValuePopOver
										v-if="showTotalValuePopOver"
										:price="order.price"
										:tip="order.tip"
										:cashback="order.cashback_used"
										:payment-method="order.payment_method"
										:change-for="order.change_for"
									>
										<a-icon
											type="info-circle"
											style="
												font-size: 12px;
												margin: 0 4px;
												padding-top: 4px;
												color: #108ee9;
											"
										/>
									</TotalValuePopOver>
									<span
										v-if="order.status !== 'CANCELED'"
										class="order-total"
									>
										R$
										<span class="order-total-value">
											{{ toPrice(orderTotal) }}
										</span>
									</span>
								</a-col>
							</a-row>
							<a-row
								type="flex"
								justify="space-between"
								style="font-size: 12px;"
								:gutter="8"
							>
								<a-col :span="4">
									<a-icon
										type="shopping"
										style="
											font-size: 14px;
											margin-right: 5px;
										"
									/>
									<span v-if="order.status === 'WAITING'">
										{{ newItemsNumber }}
										{{
											newItemsNumber > 1
												? ' novos itens'
												: ' novo item'
										}}
									</span>
									<span v-else>
										Total de {{ itemsNumber }}
										{{
											itemsNumber !== 1
												? ' itens'
												: ' item'
										}}
									</span>
								</a-col>
								<a-col :span="14" class="hide-overflow-text">
									<span v-if="alertMessage !== null">
										<a-icon
											type="exclamation-circle"
											style="
												font-size: 14px;
												margin-right: 5px;
												color: red;
											"
										/>
										{{ alertMessage }}
									</span>
									<span
										v-else-if="
											order.stars !== null &&
											order.status === 'FINISHED'
										"
									>
										<RatePopOver
											:stars="order.stars"
											:comment="order.comment || ''"
										>
											Avaliação:
											<a-rate
												:default-value="order.stars"
												disabled
												style="
													font-size: 12px;
													margin-left: 5px;
												"
											/>
										</RatePopOver>
									</span>
									<span v-else-if="order.auto_rejection">
										<a-icon
											type="exclamation-circle"
											style="
												font-size: 14px;
												margin-right: 5px;
												color: red;
											"
										/>
										Pedido recusado automaticamente -
										{{ alertMessage }}
									</span>
								</a-col>
								<a-col :span="5">
									<span class="order-time">
										{{ ShowRelativeTime() }}
									</span>
								</a-col>
							</a-row>
						</div>
					</a-skeleton>
				</template>
				<div v-if="!loading" class="body">
					<ElementList
						ref="element-list"
						title="Novos"
						:elements="order.elements"
						:order-id="order.id"
					/>
				</div>
				<a-row
					v-if="!loading"
					type="flex"
					justify="space-between"
					class="footer"
				>
					<a-col :span="2">
						<OrderHistoryPopover :history="order.history" />
					</a-col>
					<a-col :span="16">
						<a-button
							v-if="showCancelButton"
							type="dashed"
							style="margin-right: 8px;"
							@click="onCancel"
						>
							Cancelar
						</a-button>
						<div
							v-if="order.status === 'WAITING'"
							style="display: inline;"
						>
							<a-button
								style="margin-right: 8px;"
								@click="onReject"
							>
								Recusar pedido
							</a-button>
							<a-button type="primary" @click="onAccept">
								Aceitar pedido
							</a-button>
						</div>
						<SetDeliveredModal
							v-if="showSetDeliveredButton"
							ref="setDeliveredModal"
							style="display: inline;"
							@save="(payload) => onDeliver(payload)"
						>
							<a-button
								style="margin-right: 8px;"
								@click="
									() =>
										$refs.setDeliveredModal.setShowModal(
											order
										)
								"
							>
								Marcar como entregue
							</a-button>
						</SetDeliveredModal>
						<a-button
							v-if="showRequestPaymentButton"
							type="primary"
							@click="onRequestPayment"
						>
							Solicitar pagamento
						</a-button>
						<ConfirmPaymentModal
							v-if="showConfirmPayment"
							ref="confirmPaymentModal"
							style="display: inline;"
							:payment-methods-list="[]"
							@save="onReceivePayment"
						>
							<a-button
								type="primary"
								@click="
									() =>
										$refs.confirmPaymentModal.setShowModal(
											order.payment_method
										)
								"
							>
								Confirmar pagamento
							</a-button>
						</ConfirmPaymentModal>
					</a-col>
				</a-row>
			</a-collapse-panel>
		</a-collapse>
	</div>
</template>

<script>
import ElementList from './elementList'

import {
	SetDeliveredModal,
	RatePopOver,
	TotalValuePopOver,
	ConfirmPaymentModal,
	OrderHistoryPopover,
} from './drawer'

export default {
	name: 'OrderListItem',
	components: {
		ElementList,
		SetDeliveredModal,
		RatePopOver,
		TotalValuePopOver,
		ConfirmPaymentModal,
		OrderHistoryPopover,
	},
	props: {
		order: {
			type: Object,
			required: true,
		},
		table: {
			type: Object,
			default: function () {
				return null
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		itemsNumber: function () {
			return this.order.elements.reduce(
				(sum, curr) =>
					this.order.status !== 'CANCELED' ? sum + curr.amount : sum,
				0
			)
		},
		newItemsNumber: function () {
			return this.order.elements.reduce(
				(sum, curr) =>
					curr.status === 'WAITING' ? sum + curr.amount : sum,
				0
			)
		},
		statusName: function () {
			var COMAND_STATUS_NAMES = {
				Aguardando: 'WAITING',
				Aberto: 'OPEN',
				Pagamento: 'PAYING',
				Pago: 'PAID',
				Fechada: 'RECEIVING',
				Finalizado: 'FINISHED',
				Cancelado: 'CANCELED',
				Restrições: 'RESTRICTION',
			}
			for (var key in COMAND_STATUS_NAMES) {
				if (COMAND_STATUS_NAMES[key] === this.order.status) return key
			}

			return 'WAITING'
		},
		statusColor: function () {
			var COMAND_STATUS_COLOR = {
				WAITING: 'orange',
				OPEN: 'green',
				PAYING: 'gold',
				RECEIVING: 'purple',
				PAID: 'blue',
				FINISHED: 'cyan',
				CANCELED: 'red',
				RESTRICTION: 'volcano',
			}

			return COMAND_STATUS_COLOR[this.order.status]
		},
		orderTotal: function () {
			let { price, tip, cashback_used } = this.order

			price = parseFloat(price)
			tip = parseFloat(tip)
			cashback_used = parseFloat(cashback_used)

			let subtotal = ['PAYING', 'PAID', 'FINISHED'].includes(
				this.order.status
			)
				? price + tip - cashback_used
				: this.order.price_partial

			return subtotal
		},
		showCancelButton: function () {
			const allowedStates = [
				'OPEN',
				'PAYING',
				'WAITING',
				'RECEIVING',
				'RESTRICTION',
			]

			return allowedStates.includes(this.order.status)
		},
		showSetDeliveredButton: function () {
			let show = false

			if (this.order.status !== 'WAITING')
				this.order.elements.forEach((element) => {
					if (element.status === 'ACCEPTED') show = true
				})

			return show
		},
		showRequestPaymentButton: function () {
			const cases = ['OPEN', 'RESTRICTION']
			let show =
				cases.includes(this.order.status) && this.orderTotal > 0.0

			this.order.elements.forEach((element) => {
				if (element.status === 'WAITING') show = false
			})

			return show
		},
		showTotalValuePopOver: function () {
			const allowedStates = ['FINISHED', 'PAID', 'PAYING', 'RECEIVING']

			return allowedStates.includes(this.order.status)
		},
		showConfirmPayment: function () {
			return ['RECEIVING', 'PAYING'].includes(this.order.status)
		},
		alertMessage: function () {
			return this.order.cancellation_reason || this.order.rejection_reason
		},
	},
	methods: {
		onReject() {
			this.$emit('reject')
		},
		onAccept() {
			this.$emit('accept')
		},
		onCancel() {
			this.$emit('cancel')
		},
		onFinish() {
			this.$emit('finish')
		},
		ShowRelativeTime() {
			const history = ['FINISHED', 'CANCELED']

			if (history.includes(this.order.status))
				return this.moment(this.order.created_at).format('HH:mm')
			else {
				const end = this.moment(this.order.now)
				const begin = this.moment(
					this.order.last_update || this.order.created_at
				)

				return `${begin.from(end, true)} atrás`
			}
		},
		onDeliver(payload) {
			this.$emit('deliver', payload)
		},
		onRequestPayment() {
			this.$emit('requestPayment')
		},
		onReceivePayment(methodId) {
			this.$emit('receivePayment', {
				order_id: this.order.id,
				paymentMethodId: methodId,
			})
		},
		formatNumber(value) {
			return value < 10 ? `0${value}` : value
		},
		toPrice(value) {
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(value)
				.replace(/R\$/g, '')
		},
	},
}
</script>

<style lang="less" scoped>
.hide-overflow-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 5px;
}

.container {
	width: 100%;

	.collapse {
		background: #fff;

		.header {
			padding: 0 0;
			margin: 0 0;
			width: 100%;

			.order-client-name {
				font-weight: bold;
				overflow-x: hidden;
			}
			.order-number {
				color: rgba(0, 0, 0, 0.75);
				font-weight: bold;
			}
			.place-name {
				margin-left: 20px;
				margin-right: 5px;
				font-weight: bold;
				color: orange;
			}
			.order-status {
				margin-right: 10px;
				color: rgba(0, 0, 0, 0.45);
			}
			.order-time {
				padding-right: 16px;
				color: rgba(0, 0, 0, 0.55);
				font-size: 12px;
				float: right;
			}
			.order-total {
				color: rgba(0, 0, 0, 0.75);
				font-size: 11px;
				float: right;
				padding: 0 16px 0 0;

				.order-total-value {
					font-weight: bold;
					font-size: 17px;
					color: rgba(0, 0, 0, 0.7);
				}
			}
		}
		.body {
			padding: 6px 10px;
			border-top: 1px dashed #ebedf0;

			.body-order-total {
				flex-direction: column;
				padding: 10px 20px;
			}
		}
		.footer {
			position: relative;
			bottom: 0;
			width: 100%;
			border-top: 1px dashed #e8e8e8;
			padding: 10px 20px 0px 0px;
			text-align: right;
			left: 0;
			background: #fff;
			border-radius: 0 0 4px 4px;
			margin-top: 5px;

			.see-history {
				margin-left: 12px;
				font-weight: 501;
				font-size: 12px;
			}
		}
	}
}
.ant-collapse-header {
	position: relative;
	padding: 5px 0 5px 40px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 22px;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
</style>
