<template>
	<a-drawer
		title="Recursar pedido"
		:visible="showDrawer"
		width="740px"
		:closable="false"
		@close="onClose"
	>
		<div class="drawer-header">
			<span>Comanda {{ order.number }}</span>
		</div>
		<div class="drawer-body">
			<a-row
				type="flex"
				justify="center"
				style="width: 100%;"
				align="middle"
			>
				<a-col :xl="3">
					<span class="label">Motivo: </span>
				</a-col>
				<a-col :xl="18">
					<a-select v-model="flux_option" class="select">
						<a-select-option
							v-for="option in select_options"
							:key="option.value"
							:value="option.value"
						>
							{{ option.text }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row v-if="showFlux" class="flux-content">
				<a-row v-if="flux_option === 0">
					<div class="missingHeader">
						<div style="font-weight: 525;">
							Selecione os elementos em falta:
						</div>
						<p class="blockquote">
							Os elementos marcados como em falta serão
							desativados automaticamente do cardápio. Em caso de
							reposição, você deverá ativá-los manualmente na
							página de produtos.
						</p>
					</div>
					<div
						v-for="element in report"
						:key="element.id"
						class="element"
					>
						<a-row type="flex" justify="start" :gutter="16">
							<a-col><a-icon type="caret-right" /></a-col>
							<a-col>
								<a-checkbox v-model="element.checked" />
							</a-col>
							<a-col>
								<span
									style="cursor: pointer;"
									@click="
										() =>
											(element.checked = !element.checked)
									"
								>
									{{ element.item_name }}
								</span>
							</a-col>
						</a-row>
						<a-row
							v-for="subsections in element.subsections"
							:key="subsections.subsection_id"
							type="flex"
							justify="start"
							style="padding-left: 30px; width: 100%;"
						>
							<a-row class="element-subitem-header">
								{{ subsections.name }}
							</a-row>
							<a-row
								v-for="subelement in subsections.subelements"
								:key="subelement.id"
								type="flex"
								justify="start"
								:gutter="16"
								style="
									font-size: 13px;
									width: 100%;
									padding-left: 20px;
								"
							>
								<a-col>
									<a-checkbox v-model="subelement.checked" />
								</a-col>
								<a-col>
									<span
										style="cursor: pointer;"
										@click="
											() =>
												(subelement.checked = !subelement.checked)
										"
									>
										{{ subelement.name }}
									</span>
								</a-col>
							</a-row>
						</a-row>
						<a-row class="reject-message">
							<a-input
								v-if="element.showMessageField()"
								v-model="element.message"
								size="small"
								placeholder="Informe ao seu cliente o motivo da rejeição do item"
								style="width: 100%;"
							/>
						</a-row>
					</div>
				</a-row>
				<a-row
					v-else-if="flux_option === 1"
					type="flex"
					class="centered"
					align="middle"
				>
					<a-col :xl="3">
						<span class="label">Mensagem: </span>
					</a-col>
					<a-col :xl="18">
						<a-textarea
							v-model="message"
							placeholder="Informe ao seu cliente o motivo da rejeição do pedido"
							auto-size
							style="width: 80%;"
						/>
					</a-col>
				</a-row>
			</a-row>
		</div>
		<div class="drawer-footer">
			<a-button style="margin-right: 8px;" @click="onClose">
				Cancelar
			</a-button>
			<a-button type="primary" :disabled="!validated" @click="reject">
				Enviar
			</a-button>
		</div>
	</a-drawer>
</template>

<script>
const select_options = [
	{ value: 0, text: 'Elementos do pedido estão em falta' },
	{ value: 1, text: 'Outro...' },
]

export default {
	name: 'RejectOrderDrawer',
	data() {
		return {
			showDrawer: false,
			flux_option: 'Selecione um motivo',
			report: [],
			message: '',
			order: {},
			select_options,
		}
	},
	computed: {
		validated: function () {
			let show = false
			switch (this.flux_option) {
				case 0:
					if (this.validateReport() === true) show = true
					break
				case 1:
					if (this.message !== '') show = true
					break
				default:
					break
			}

			return show
		},
	},
	methods: {
		setShowDrawer(order) {
			this.showDrawer = true
			this.order = order
			this.mount()
		},
		onClose() {
			this.showDrawer = false
			this.clearDrawer()
		},
		reject() {
			const payload = this.mountPayload()
			this.$emit('save', payload)

			this.showDrawer = false
			this.clearDrawer()
		},
		clearDrawer() {
			this.message = ''
			this.report = {}
			this.order = {}
		},
		showFlux() {
			return this.flux_option !== null
		},
		mount() {
			this.report = this.order.elements
				.filter((element) => element.status === 'WAITING')
				.map((element) => {
					return {
						id: element.id,
						item_name: element.item_name,
						checked: false,
						message: '',
						subsections: element.subsections.map((subsection) => {
							return {
								id: subsection.id,
								name: subsection.name,
								subelements: subsection.subelements.map(
									(subelemet) => {
										return {
											id: subelemet.id,
											name: subelemet.subitem_name,
											checked: false,
										}
									}
								),
							}
						}),
						showMessageField() {
							let res = this.checked
							this.subsections.forEach((subsection) => {
								subsection.subelements.forEach((subelemet) => {
									if (subelemet.checked) res = true
								})
							})

							return res
						},
					}
				})
		},
		validateReport() {
			let res = false
			if (this.report.length > 0) {
				this.report.forEach((element) => {
					if (element.showMessageField()) res = true
				})
			}

			return res
		},
		mountPayload() {
			let payload = {
				order_id: this.order.id,
				data: {
					order_rejection_reason:
						this.flux_option === 1
							? this.message
							: this.select_options[this.flux_option].text,
					rejected_elements: [],
				},
			}

			if (this.report.length > 0 && this.flux_option === 0) {
				payload.data.rejected_elements = this.report
					.filter((e) => e.showMessageField())
					.map((element) => {
						let aux = {
							id: element.id,
							item_available: !element.checked,
							rejection_reason: element.message,
						}

						aux.rejected_subelements = element.subsections.reduce(
							(result, subsection) =>
								result.concat(
									subsection.subelements
										.filter(
											(subelement) => subelement.checked
										)
										.map((subelement) => {
											return {
												id: subelement.id,
												subitem_available: !subelement.checked,
											}
										})
								),
							[]
						)

						return aux
					})
			}

			return payload
		},
	},
}
</script>

<style lang="less" scoped>
.drawer-header {
	font-weight: bold;
	margin: 0 0 20px 10px;
	color: orange;
}
.drawer-body {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 28px;

	.select {
		width: 80%;
	}

	.label {
		float: right;
		padding-right: 10px;
	}

	.flux-content {
		width: 100%;

		.centered {
			display: flex;
			justify-content: center;
			margin: 20px 0;
		}

		.missingHeader {
			padding: 10px 10px 5px 10px;
			margin-top: 20px;
			border-top: 1px solid hsv(0, 0, 85%);
			border-bottom: 1px solid hsv(0, 0, 85%);
		}

		.blockquote {
			font-size: 90%;
			color: #697b8c;
			border-left: 4px solid #ebedf0;
			padding-left: 0.8em;
			margin: 1em 0;
		}

		.element {
			padding: 5px 0 5px 30px;
			background: #fafafa;
			border-bottom: 1px solid hsv(0, 0, 85%);
			color: #000;

			.element-subitem-header {
				color: rgba(0, 0, 0, 0.3);
				font-size: 12px;
				display: flex;
				width: 100%;
			}

			.reject-message {
				padding: 15px 20px 5px 0;
			}
		}
	}
}

.drawer-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid hsv(0, 0, 85%);
	padding: 10px 16px;
	text-align: right;
	left: 0;
	background: #fff;
	border-radius: 0 0 4px 4px;
	margin-top: 25px;
}
</style>
